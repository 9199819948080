import { useCallback, useEffect, useMemo, useState } from "react";

import { DataSet } from "@/model";
import { ModuleGazFmbInitOutput, NewModuleGazFmbParamOutput } from "./model";
import { transpose } from "@/util";
import FossilyticsChart, {
  FossilyticsChartAxis,
  FossilyticsChartLegendData,
  FossilyticsChartLine,
  FossilyticsChartLineConstraint,
  FossilyticsChartSeries,
} from "@/components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn } from "@/components/FossilyticsGrid";

import useThemeStyling from "@/utils/useThemeStyling";
import Tabs from "@/components/Tabs";
import LoadingIndicator from "@/components/LoadingIndicator";
import _ from "lodash";

interface ModuleGazFmbOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  fmb?: ModuleGazFmbInitOutput;
  param?: NewModuleGazFmbParamOutput;
  line?: number[][];
  onLineChange?: (line: number[][] | [string, number][]) => void;
  manualPoints: number[][];
  isUserLine: boolean;
}

const dataTableColumns = [
  //  TODO
  // {key: 'd', type: 'date', header: 'Date'},
  { key: "q", type: "number", header: "q" },
  { key: "Q", type: "number", header: "Q" },
  { key: "Pwf", type: "number", header: "Pwf" },
  { key: "nRate", type: "number", header: "Norm. Rate" },
  { key: "nQ", type: "number", header: "Norm. Q" },
  { key: "Pr", type: "number", header: "Pr" },
  { key: "z", type: "number", header: "z" },
  { key: "Pr/z", type: "number", header: "Pr/z" },
  { key: "mp(Pr*)", type: "number", header: "mp(Pr*)" },
  { key: "Pr*", type: "number", header: "Pr*" },
  { key: "z*", type: "number", header: "z*" },
  { key: "Pr*/z*", type: "number", header: "Pr*/z*" },
  { key: "PI", type: "number", header: "PI" },
] as FossilyticsGridColumn[];

function ModuleGazFmbOutputView({ isLoading, fmb, line, param, onLineChange, manualPoints, isUserLine }: ModuleGazFmbOutputViewProps) {
  const [dataTableRows, setDataTableRows] = useState<number[][]>([]);

  useEffect(() => {
    const result: any[] = [];
    if (fmb) {
      const count = fmb.diagnostic["Q"].length;
      for (let i = 0; i < count; i++) {
        result.push([
          fmb.diagnostic["q"][i],
          fmb.diagnostic["Q"][i],
          fmb.diagnostic["Pwf"][i],
          fmb.diagnostic["Norm. Rate"][i],
          fmb.diagnostic["Norm. Q"][i],
          fmb.diagnostic["Pr"][i],
          fmb.diagnostic["z"][i],
          fmb.diagnostic["Pronz"][i],
          fmb.diagnostic_fpz["mp(Pr_star)"][i],
          fmb.diagnostic_fpz["Pr_star"][i],
          fmb.diagnostic_fpz["z_star"][i],
          fmb.diagnostic_fpz["PronZ_star"][i],
          fmb.diagnostic["PI"][i],
        ]);
      }
    }
    setDataTableRows(result);
  }, [fmb]);

  const { palette } = useThemeStyling();

  const piSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["Q"].length;
    const piData = Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic["PI"][i]]);

    return [
      {
        name: "Productivity Index",
        type: "scatter",
        color: palette.customColor.blue,
        data: piData,
        defaultDisabled: false,
      },
      {
        name: "Average Productivity Index(Mid)",
        type: "line",
        color: palette.customColor.red,
        lineType: "dashed",
        hideSymbol: true,
        data: param
          ? [
              [0, param.results[1].pi_dim],
              [Math.max(...piData.map((p) => p[0])), param.results[1].pi_dim],
            ]
          : [],
        defaultDisabled: false,
      },
    ];
  }, [palette, fmb, param]);

  const fmbSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["q"].length;

    const series: FossilyticsChartSeries[] = [
      {
        name: "Flowing Material Balance",
        type: "scatter",
        color: palette.customColor.red,
        data: Array.from(Array(dataLength - fmb.pts_length).keys()).map((i) => [fmb.diagnostic["Norm. Q"][i], fmb.diagnostic["Norm. Rate"][i]]),
      },
      {
        name: "Latest linear trend",
        type: "scatter",
        color: palette.customColor.black,
        data: Array.from(Array(fmb.pts_length).keys())
          .map((i) => dataLength - i)
          .map((i) => [fmb.diagnostic["Norm. Q"][i], fmb.diagnostic["Norm. Rate"][i]]),
      },
      ...(!isUserLine
        ? [
            {
              name: "FMB Low case",
              type: "line",
              hideSymbol: true,
              color: palette.primary.main,
              data: transpose(fmb.uncertainty[0]),
            },
            {
              name: "FMB High case",
              type: "line",
              hideSymbol: true,
              color: palette.customColor.yellow,
              data: transpose(fmb.uncertainty[2]),
            },
          ]
        : []),
      ...piSeries.map((s) => ({ ...s, defaultDisabled: true, name: s.name + " ." })),
    ];

    return series;
  }, [fmb, palette.customColor.red, palette.customColor.black, palette.customColor.yellow, palette.primary.main, isUserLine, piSeries]);

  const fmbLines = useMemo<FossilyticsChartLine[]>(() => {
    if (!fmb || !line) return [];
    return [
      {
        name: "FMB Mid case",
        color: palette.customColor.red,
        line: line,
        xMin: Math.max(...fmb.diagnostic["Norm. Q"]),
        xConstraint: FossilyticsChartLineConstraint.AXIS,
        yConstraint: FossilyticsChartLineConstraint.AXIS,
      },
    ];
  }, [palette, fmb, line]);

  const pronzSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["Q"].length;

    return [
      {
        name: "Model P/z",
        type: "scatter",
        color: palette.customColor.red,
        data: Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic["Pronz"][i]]),
      },
      {
        name: "Flowing P*/z*",
        type: "scatter",
        color: palette.customColor.green,
        data: Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic_fpz["PronZ_star"][i]]),
      },
      {
        name: "Static P/z",
        type: "scatter",
        symbolSize: 12,
        color: palette.customColor.black,
        data: manualPoints,
      },
      {
        name: "FMB P/z Best Fit",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.green,
        data: [[0, fmb.diagnostic["Pronz"][0]], line ? line[1] : [fmb.ogip, 0]],
      },
    ];
  }, [palette, fmb, manualPoints, line]);

  const isSeriesDisabledInCombined = useCallback((s: FossilyticsChartSeries) => {
    switch (s.name) {
      case "FMB Low case":
      case "FMB High case":
      case "Model P/z":
      case "Productivity Index":
      case "Average Productivity Index":
        return true;
      default:
        return false;
    }
  }, []);

  const allSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      ...fmbSeries.reduce((arr, s, i, series) => {
        let data = s.data;
        // Merge FMB data and latest linear trend for combined chart only
        if (s.name === "Flowing Material Balance") {
          const latestLinearTrend = series.find((s) => s.name === "Latest linear trend");
          if (latestLinearTrend) {
            data = [...data, ...latestLinearTrend.data];
          }
        } else if (s.name === "Latest linear trend") {
          return arr;
        }

        arr.push({ ...s, data, defaultDisabled: isSeriesDisabledInCombined(s), yAxisIndex: 0 });
        return arr;
      }, [] as FossilyticsChartSeries[]),
      ...fmbLines.map<FossilyticsChartSeries>((l) => ({
        ...l,
        type: "line",
        hideSymbol: true,
        data: l.line ? l.line : [],
        yAxisIndex: 0,
      })),
      ...pronzSeries.map((s) => ({ ...s, defaultDisabled: isSeriesDisabledInCombined(s), yAxisIndex: 1 })),
    ];
  }, [fmbSeries, fmbLines, pronzSeries, isSeriesDisabledInCombined]);

  const fmbLegends = useMemo<FossilyticsChartLegendData[]>(() => {
    const legends: FossilyticsChartLegendData[] = fmbSeries.map((s) => ({ name: s.name }));
    const lowIndex = legends.findIndex((l) => l.name === "FMB Low case");
    legends.splice(lowIndex + 1, 0, { name: "FMB Mid case" });

    return legends;
  }, [fmbSeries]);

  const allLegends = useMemo<FossilyticsChartLegendData[]>(() => {
    const legends: FossilyticsChartLegendData[] = allSeries.map((s) => ({ name: s.name } as FossilyticsChartLegendData));
    const lowIndex = legends.findIndex((l) => l.name === "FMB Low case");
    const midIndex = legends.findIndex((l) => l.name === "FMB Mid case");
    legends.splice(lowIndex + 1, 0, ...(legends[midIndex] ? [legends[midIndex]] : []));
    legends.splice(midIndex + 1, 1);
    return legends;
  }, [allSeries]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Cumulative Production (MMscf)", type: "value", color: palette.customColor.black, min: 0 }];
  }, [palette]);

  const piXAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const max = piSeries.length === 1 ? Math.max(...piSeries[0].data.map((d) => d[0])) * 2.5 : undefined;
    return [{ ...xAxes[0], max }];
  }, [xAxes, piSeries]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      { name: "Productivity Index", type: "value", color: palette.customColor.black, min: 0, nameGap: 50, offset: 0 },
      { name: "P/z (psia)", type: "value", color: palette.customColor.black, min: 0, nameGap: 30, offset: 0 },
    ],
    [palette]
  );
  const piYAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const max = piSeries.length === 1 ? Math.max(...piSeries[0].data.map((d) => d[1])) * 1.5 : undefined;
    return [{ ...yAxes[0], max }];
  }, [yAxes, piSeries]);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>FMB</span>,
        content: (
          <div style={{ height: 620 }}>
            <FossilyticsChart
              id="gaz_fmb_fmb"
              isLoading={isLoading}
              xAxes={xAxes}
              yAxes={[yAxes[0]]}
              series={_.cloneDeep(fmbSeries)}
              lines={fmbLines}
              onLineChange={onLineChange}
              legends={fmbLegends}
            />
          </div>
        ),
        key: "FMB",
      },
      {
        label: <span>P/z</span>,
        content: (
          <div style={{ height: 620 }}>
            <FossilyticsChart id="gaz_fmb_pz" isLoading={isLoading} xAxes={xAxes} yAxes={[yAxes[1]]} series={pronzSeries} />
          </div>
        ),
        key: "P/z",
      },
      {
        label: <span>PI</span>,
        content: (
          <div style={{ height: 620 }}>
            <FossilyticsChart
              id="gaz_fmb_pi"
              isLoading={isLoading}
              xAxes={piXAxes}
              yAxes={piYAxes}
              series={_.cloneDeep(piSeries)}
              legends={piSeries.map((serie) => ({ name: serie.name }))}
            />
          </div>
        ),
        key: "PI",
      },
      {
        label: <span>Combined Chart</span>,
        content: (
          <div style={{ height: 620 }}>
            <FossilyticsChart id="gaz_fmb_combined" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={allSeries} legends={allLegends} />
          </div>
        ),
        key: "Combined Chart",
      },
      {
        label: <span>Data Table</span>,
        content: (
          <div style={{ height: "100%", padding: "1em" }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <FossilyticsGrid style={{ overflow: "auto", height: "100%" }} columns={dataTableColumns} data={dataTableRows} />
            )}
          </div>
        ),
        key: "Data Table",
      },
    ];
  }, [
    allLegends,
    allSeries,
    dataTableRows,
    fmbLegends,
    fmbLines,
    fmbSeries,
    isLoading,
    onLineChange,
    piSeries,
    piXAxes,
    piYAxes,
    pronzSeries,
    xAxes,
    yAxes,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <Tabs alwaysRender tabList={tabList} />
    </div>
  );
}

export default ModuleGazFmbOutputView;
