import {
  FormationCompressibilityCorrelationEnum,
  GeomechanicModelEnum,
  RelativePermeabilityCorrelationEnum,
  SkinModelEnum,
  ViscosityCorrelationEnum,
  WaterCompressibilityCorrelation,
  WaterViscosityCorrelation,
  WellInputEnum,
  ZCorrelation,
} from "@/models/InputGeneric";
import { DistributionApplicationEnum, DistributionTypeEnum } from "@/models/koldunV2";

export const defaultValueMap: {
  [key: string]: {
    [distributionKey: string]: any;
  };
} = {
  permeability_horizontal_distribution: {
    Uniform: {
      low_value: 25,
      high_value: 50,
    },
    Triangular: {
      low_value: 25,
      mode_value: 40,
      high_value: 50,
    },
    Lognormal: {
      mean_value: 40,
      standard_deviation: 10,
    },
    Normal: {
      mean_value: 40,
      standard_deviation: 10,
    },
    Fixed: {
      value: 50,
    },
  },
  net_pay_distribution: {
    Fixed: {
      value: 50,
    },
    Lorenz: {
      value: 12,
      lorenz_factor: 0.4,
    },
  },
  initial_water_saturation_distribution: {
    Uniform: {
      low_value: 70,
      high_value: 95,
    },
    Triangular: {
      low_value: 70,
      mode_value: 80,
      high_value: 95,
    },
    Lognormal: {
      mean_value: 80,
      standard_deviation: 15,
    },
    Normal: {
      mean_value: 80,
      standard_deviation: 15,
    },
    Fixed: {
      value: 100,
    },
  },
  initial_pressure_distribution: {
    Uniform: {
      low_value: 550,
      high_value: 800,
    },
    Triangular: {
      low_value: 550,
      mode_value: 700,
      high_value: 800,
    },
    Lognormal: {
      mean_value: 700,
      standard_deviation: 50,
    },
    Normal: {
      mean_value: 700,
      standard_deviation: 50,
    },
    Fixed: {
      value: 700,
    },
  },
  porosity_distribution: {
    Uniform: {
      low_value: 0.4,
      high_value: 1.5,
    },
    Triangular: {
      low_value: 0.4,
      mode_value: 0.7,
      high_value: 1.5,
    },
    Lognormal: {
      mean_value: 0.7,
      standard_deviation: 0.02,
    },
    Normal: {
      mean_value: 0.7,
      standard_deviation: 0.02,
    },
    Fixed: {
      value: 0.5,
    },
  },
  langmuir_pressure_distribution: {
    Uniform: {
      low_value: 320,
      high_value: 750,
    },
    Triangular: {
      low_value: 320,
      mode_value: 650,
      high_value: 750,
    },
    Lognormal: {
      mean_value: 650,
      standard_deviation: 120,
    },
    Normal: {
      mean_value: 650,
      standard_deviation: 120,
    },
    Fixed: {
      value: 650,
    },
  },
  // volumetric
  langmuir_volume_distribution: {
    Uniform: {
      low_value: 320,
      high_value: 750,
    },
    Triangular: {
      low_value: 320,
      mode_value: 650,
      high_value: 750,
    },
    Lognormal: {
      mean_value: 650,
      standard_deviation: 120,
    },
    Normal: {
      mean_value: 650,
      standard_deviation: 120,
    },
    Fixed: {
      value: 650,
    },
  },
  desorption_pressure_distribution: {
    Uniform: {
      low_value: 250,
      high_value: 550,
    },
    Triangular: {
      low_value: 250,
      mode_value: 500,
      high_value: 550,
    },
    Lognormal: {
      mean_value: 500,
      standard_deviation: 20,
    },
    Normal: {
      mean_value: 500,
      standard_deviation: 20,
    },
    Fixed: {
      value: 500,
    },
  },
  rock_density_distribution: {
    Uniform: {
      low_value: 1.2,
      high_value: 1.6,
    },
    Triangular: {
      low_value: 1.2,
      mode_value: 1.4,
      high_value: 1.6,
    },
    Lognormal: {
      mean_value: 1.4,
      standard_deviation: 0.2,
    },
    Normal: {
      mean_value: 1.4,
      standard_deviation: 0.2,
    },
    Fixed: {
      value: 1.4,
    },
  },

  // ogip
  ogip_distribution: {
    Uniform: {
      low_value: 4000,
      high_value: 5000,
    },
    Triangular: {
      low_value: 4000,
      mode_value: 4400,
      high_value: 5000,
    },
    Lognormal: {
      mean_value: 4400,
      standard_deviation: 300,
    },
    Normal: {
      mean_value: 4400,
      standard_deviation: 300,
    },
    Fixed: {
      value: 4400,
    },
  },
  gas_content_saturation_distribution: {
    Uniform: {
      low_value: 15,
      high_value: 30,
    },
    Triangular: {
      low_value: 15,
      mode_value: 25,
      high_value: 30,
    },
    Lognormal: {
      mean_value: 25,
      standard_deviation: 7,
    },
    Normal: {
      mean_value: 25,
      standard_deviation: 7,
    },
    Fixed: {
      value: 25,
    },
  },
  initial_gas_content_distribution: {
    Uniform: {
      low_value: 490,
      high_value: 600,
    },
    Triangular: {
      low_value: 490,
      mode_value: 515,
      high_value: 600,
    },
    Lognormal: {
      mean_value: 515,
      standard_deviation: 20,
    },
    Normal: {
      mean_value: 515,
      standard_deviation: 20,
    },
    Fixed: {
      value: 515.86,
    },
  },
};

export const suffixMap: {
  [key: string]: string;
} = {
  permeability_horizontal_distribution: "mD",
  net_pay_distribution: "ft",
  initial_water_saturation_distribution: "%",
  initial_pressure_distribution: "psia",
  porosity_distribution: "%",
  langmuir_pressure_distribution: "psia",
  // volumetric
  langmuir_volume_distribution: "scf/ton",
  desorption_pressure_distribution: "psia",
  rock_density_distribution: "gr/cc",

  // ogip
  ogip_distribution: "MMscf",
  gas_content_saturation_distribution: "%",
  initial_gas_content_distribution: "sci/ton",
};

export const lorenzSuffix = {
  Lorenz: {
    value: "ft",
    lorenz_factor: "dim",
  },
};

export const defaultDistributionValue = {
  distribution_type: DistributionTypeEnum.Fixed,
  distribution_application: DistributionApplicationEnum.PerMeasure,
};

export const ogipOnlyDistributionKey = ["ogip_distribution", "gas_content_saturation_distribution", "initial_gas_content_distribution"];
export const volumetricOnlyDistributionKey = ["langmuir_volume_distribution", "desorption_pressure_distribution", "rock_density_distribution"];

export const restDistributionKey = [
  "permeability_horizontal_distribution",
  "net_pay_distribution",
  "initial_water_saturation_distribution",
  "initial_pressure_distribution",
  "porosity_distribution",
  "langmuir_pressure_distribution",
];

export const defaultDependencyMatrixOgip = {
  "permeability_horizontal:porosity": 0,
  "permeability_horizontal:initial_pressure": 0,
  "permeability_horizontal:initial_water_saturation": 0,
  "permeability_horizontal:net_pay": 0,
  "permeability_horizontal:langmuir_pressure": 0,
  "permeability_horizontal:ogip": 0,
  "permeability_horizontal:gas_content_saturation_distribution": 0,
  "permeability_horizontal:initial_gas_content": 0,
  "porosity:initial_pressure": 0,
  "porosity:initial_water_saturation": 0,
  "porosity:net_pay": 0,
  "porosity:langmuir_pressure": 0,
  "porosity:ogip": 0,
  "porosity:gas_content_saturation_distribution": 0,
  "porosity:initial_gas_content": 0,
  "initial_pressure:initial_water_saturation": 0,
  "initial_pressure:net_pay": 0,
  "initial_pressure:langmuir_pressure": 0,
  "initial_pressure:ogip": 0,
  "initial_pressure:gas_content_saturation_distribution": 0,
  "initial_pressure:initial_gas_content": 0,
  "initial_water_saturation:net_pay": 0,
  "initial_water_saturation:langmuir_pressure": 0,
  "initial_water_saturation:ogip": 0,
  "initial_water_saturation:gas_content_saturation_distribution": 0,
  "initial_water_saturation:initial_gas_content": 0,
  "net_pay:langmuir_pressure": 0,
  "net_pay:ogip": 0,
  "net_pay:gas_content_saturation_distribution": 0,
  "net_pay:initial_gas_content": 0,
  "langmuir_pressure:ogip": 0,
  "langmuir_pressure:gas_content_saturation_distribution": 0,
  "langmuir_pressure:initial_gas_content": 0,
  "ogip:gas_content_saturation_distribution": 0,
  "ogip:initial_gas_content": 0,
  "gas_content_saturation_distribution:initial_gas_content": 0,
};

export const defaultDependencyMatrixVolumetric = {
  "permeability_horizontal:porosity": 0,
  "permeability_horizontal:initial_pressure": 0,
  "permeability_horizontal:initial_water_saturation": 0,
  "permeability_horizontal:net_pay": 0,
  "permeability_horizontal:langmuir_pressure": 0,
  "permeability_horizontal:langmuir_volume": 0,
  "permeability_horizontal:desorption_pressure": 0,
  "permeability_horizontal:rock_density": 0,
  "porosity:initial_pressure": 0,
  "porosity:initial_water_saturation": 0,
  "porosity:net_pay": 0,
  "porosity:langmuir_pressure": 0,
  "porosity:langmuir_volume": 0,
  "porosity:desorption_pressure": 0,
  "porosity:rock_density": 0,
  "initial_pressure:initial_water_saturation": 0,
  "initial_pressure:net_pay": 0,
  "initial_pressure:langmuir_pressure": 0,
  "initial_pressure:langmuir_volume": 0,
  "initial_pressure:desorption_pressure": 0,
  "initial_pressure:rock_density": 0,
  "initial_water_saturation:net_pay": 0,
  "initial_water_saturation:langmuir_pressure": 0,
  "initial_water_saturation:langmuir_volume": 0,
  "initial_water_saturation:desorption_pressure": 0,
  "initial_water_saturation:rock_density": 0,
  "net_pay:langmuir_pressure": 0,
  "net_pay:langmuir_volume": 0,
  "net_pay:desorption_pressure": 0,
  "net_pay:rock_density": 0,
  "langmuir_pressure:langmuir_volume": 0,
  "langmuir_pressure:desorption_pressure": 0,
  "langmuir_pressure:rock_density": 0,
  "langmuir_volume:desorption_pressure": 0,
  "langmuir_volume:rock_density": 0,
  "desorption_pressure:rock_density": 0,
};

export const defaultOgipMatrixKey = [
  "permeability_horizontal",
  "porosity",
  "initial_pressure",
  "initial_water_saturation",
  "net_pay",
  "langmuir_pressure",
  "ogip",
  "gas_content_saturation_distribution",
  "initial_gas_content",
];

export const defaultVolumetricKey = [
  "permeability_horizontal",
  "porosity",
  "initial_pressure",
  "initial_water_saturation",
  "net_pay",
  "langmuir_pressure",
  "langmuir_volume",
  "desorption_pressure",
  "rock_density",
];

export const defaultPumpHeadValue = {
  initial_liquid_level: 100,
  minimum_pump_head: 20,
  pump_depth: 1000,
  liquid_draw_down_rate: 90,
};

export const defaultNoModelValue = {
  initial_liquid_level: null,
  minimum_pump_head: null,
  pump_depth: null,
  liquid_draw_down_rate: null,
};

// if the user change number of measure to 0 and chang eit back on
export const getDefaultMeasure = (type: "Ogip" | "Volumetric") => ({
  langmuir_volume_distribution: {
    distribution_parameters: [
      {
        value: 650,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  desorption_pressure_distribution: {
    distribution_parameters: [
      {
        value: 690,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  rock_density_distribution: {
    distribution_parameters: [
      {
        value: 1.4,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  number_of_layers: 1,
  reservoir_area: 90,
  formation_temperature: 180,
  selected_formation_compressibility_correlation: FormationCompressibilityCorrelationEnum.Constant,
  formation_compressibility: 0.0001,
  layer_depth: 1000,
  permeability_vertical: 10,
  specific_gravity: 0.6,
  selected_z_correlation: ZCorrelation.DranchukAbouKassem,
  nitrogen: 0,
  carbon_dioxide: 0,
  hydrogen_sulphide: 0,
  selected_gas_viscosity_correlation: ViscosityCorrelationEnum.GonzalesEakin,
  salinity: 0,
  selected_water_compressibility_correlation: WaterCompressibilityCorrelation.BeggsBrill,
  selected_water_viscosity_correlation: WaterViscosityCorrelation.BeggsBrill,
  selected_skin_model: SkinModelEnum.Constant,
  skin_value: 0,
  relative_permeability: {
    selected_relative_permeability_correlation: RelativePermeabilityCorrelationEnum.Corey,
    parameter: {
      krg_max: 1,
      connate_water_saturation: 20,
      residual_gas_saturation: 5,
      krw_max: 1,
      ng: 1,
      nw: 1,
    },
  },
  geomechanics: {
    selected_geomechanic_model: GeomechanicModelEnum.NoModel,
    parameter: null,
  },
  wellbore_radius: 0.23,
  well_inputs: {
    selected_well_type: WellInputEnum.Vertical,
    well_parameters: null,
  },
  permeability_horizontal_distribution: {
    distribution_parameters: [
      {
        value: 100,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  net_pay_distribution: {
    distribution_parameters: [
      {
        value: 50,
        lorenz_factor: 0.4,
      },
    ],
    distribution_type: DistributionTypeEnum.Lorenz,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  initial_water_saturation_distribution: {
    distribution_parameters: [
      {
        value: 100,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  initial_pressure_distribution: {
    distribution_parameters: [
      {
        value: 700,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  porosity_distribution: {
    distribution_parameters: [
      {
        value: 0.5,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  langmuir_pressure_distribution: {
    distribution_parameters: [
      {
        value: 650,
      },
    ],
    distribution_type: DistributionTypeEnum.Fixed,
    distribution_application: DistributionApplicationEnum.PerMeasure,
  },
  dependency_matrix_values: type === "Ogip" ? defaultDependencyMatrixOgip : defaultDependencyMatrixVolumetric,
});
